/* CARDS LAYOUT
---------------------------------------- */
.av-card-forum-image {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.av-cards ul.topiclist li.row.av-card .row-item {
  height: 400px;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.av-cards.forabg {
  margin-bottom: 0;
}

.av-cards .av-card-row {
  padding: 0 25px;
}

.av-card-short .av-card-image-wrap {
  height: 230px;
  position: relative;
}

.av-card-expanded .av-card-image-wrap {
  position: relative;
}

.av-card-forum-image-box {
  position: relative;
  overflow: hidden;
}

.av-card-forum-image-box:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

a.av-card-image-link {
  display: block;
  height: 230px;
  width: 100%;
  visibility: visible;
  transition: height 0.3s;
  transition-delay: 0.2s;
}

a.av-card-image-link:hover {
  opacity: 1;
}

.av-card-forum-image {
  height: 230px;
  width: 100%;
}

.av-card-expanded a.av-card-image-link {
  visibility: hidden;
  height: 0;
}

.av-card-short a.av-card-image-link {
  transition: none;
}

.av-card-expanded .av-card-image-inner {
  visibility: hidden;
  opacity: 0;
}

.av-card-short .av-card-image-inner {
  visibility: visible;
  opacity: 1;
}

.av-card-expanded .av-card-forum-box {
  overflow: hidden;
  max-height: 355px;
}

.av-card-forum-info {
  max-height: 40px;
  position: relative;
  transition: all 0.3s;
  transition-delay: 0.2s;
}

.av-card-expanded .av-card-forum-info {
  margin-bottom: 15px;
  overflow-y: auto;
  max-height: 256px;
}

.av-card-expanded .av-card-forum-info .av-card-forum-desc {
  white-space: normal;
}

.av-card-short .av-card-forum-info .av-card-forum-desc {
  transition-delay: 0s;
}

.av-card-short .av-card-forum-info {
  transition-delay: 0s;
}

.av-card-cat-title-box {
  padding: 20px 25px;
  text-align: left;
  font-size: 11px;
  font-weight: 500;
  text-indent: 0;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  text-transform: uppercase;
  position: absolute;
  bottom: 70px;
}

.av-card-expanded .av-card-cat-title-box {
  display: none;
}

.av-card-forum-title {
  padding: 20px 25px;
  padding-right: 75px;
  position: relative;
  width: 100%;
}

.av-card-forum-icon:before {
  font-size: 18px;
  width: 34px;
  height: 34px;
  line-height: 34px !important;
  text-align: center;
  display: block;
  position: absolute;
  top: 182px;
  right: 15px;
  z-index: 1;
  opacity: 0.7;
  padding: 0;
}

.row-item:hover .av-card-forum-icon:before {
  opacity: 1;
}

.av-card-expanded .av-card-forum-icon:before {
  display: none;
}

.av-card-short .av-card-forum-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.av-card-forum-box .av-card-forum-title {
  visibility: hidden;
  padding: 0;
  transition-delay: 0.2s;
}

.av-card-short .av-card-forum-box .av-card-forum-title {
  transition: none;
  transition-delay: 0s;
  padding: 0;
  height: 0;
}

.av-card-expanded .av-card-forum-box .av-card-forum-title {
  max-height: 80px;
  white-space: normal;
  visibility: visible;
  padding: 25px 67px 25px 25px;
  height: 100%;
}

.av-card-short .av-card-forum-image-box .av-card-forum-title {
  position: absolute;
  bottom: 0;
}

.av-card-expanded .av-card-forum-image-box .av-card-forum-title {
  display: none;
}

a.av-card-more-info-toogle, .av-rank-title {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
  text-align: center;
  height: 66px;
  width: 57px;
  line-height: 66px;
  z-index: 1;
}

.av-cards-members-list .av-card-short a.av-card-more-info-toogle {
  z-index: 0;
  opacity: 1;
}

.av-rank-title {
  right: auto;
  left: 0;
}

.av-card-expanded .av-rank-title {
  display: none;
}

a.av-card-edit-img-btn {
  position: absolute !important;
  top: 20px;
  left: 25px;
  font-size: 20px;
  z-index: 1;
  display: inline-block;
  width: 40px;
  height: 40px;
  opacity: 0.7;
}

.av-card-short a.av-card-edit-img-btn:hover {
  opacity: 1;
}

.av-card-expanded a.av-card-edit-img-btn {
  display: none;
}

a.av-card-more-info-toogle:focus {
  outline: none;
}

.av-card-short a.av-card-more-info-toogle {
  opacity: 0.7;
}

.av-card-short a.av-card-more-info-toogle:hover {
  opacity: 1;
}

.av-card-forum-title .forumtitle {
  font-weight: 500;
  font-size: 14px;
}

.av-card-expanded .av-card-forum-title {
  max-height: 80px;
  overflow: hidden;
}

.av-card-forum-desc {
  padding: 20px 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.av-card-subforums {
  margin-bottom: 15px;
  height: 0;
  overflow: hidden;
  transition: all 0.3s;
  transition-delay: 0.3s;
  opacity: 0;
  visibility: hidden;
}

.av-card-expanded .av-card-subforums {
  visibility: visible;
  opacity: 1;
  height: 100%;
}

.av-card-short .av-card-subforums {
  transition: none;
  transition-delay: 0s;
  visibility: hidden;
  opacity: 0;
  height: 0;
}

.av-card-forum-mod {
  visibility: hidden;
  transition: all 0.3s;
  transition-delay: 0.3s;
  opacity: 0;
}

.av-card-short .av-card-forum-mod {
  transition: none;
  transition-delay: 0s;
  visibility: hidden;
}

.av-card-expanded .av-card-forum-mod {
  visibility: visible;
  opacity: 1;
}

.av-card-forum-icons {
  font-size: 18px;
  visibility: hidden;
  transition: all 0.3s;
  transition-delay: 0.3s;
  margin-top: 20px;
  margin-bottom: 30px;
  opacity: 0;
}

.av-card-short .av-card-forum-icons {
  transition: none;
  transition-delay: 0s;
  visibility: hidden;
}

.av-card-expanded .av-card-forum-icons {
  visibility: visible;
  opacity: 1;
}

.av-card-forum-icons a {
  float: none !important;
  display: inline-block;
  margin-right: 15px;
}

.av-card-forum-icons a.feed-icon-forum {
  float: right;
  margin: 0;
  margin-right: 15px;
}

.av-card-bottom-info {
  position: absolute;
  bottom: 0;
  height: 71px;
  width: 100%;
  display: table;
  table-layout: fixed;
  max-width: 100%;
}

.av-card-bottom-info-inner {
  display: table-row;
}

.av-card-bottom-right-info {
  text-align: right;
  font-size: 12px;
  font-weight: 500;
  width: 45%;
  display: table-cell;
  vertical-align: middle;
}

.av-card-bottom-right-info i {
  margin-right: 10px;
}

.av-card-bottom-right-info a.feed-icon-forum {
  float: none;
  margin: 0;
}

.av-card-bottom-right-info a.feed-icon-forum i {
  margin-right: 0;
}

.av-card-bottom-right-info span {
  margin-left: 15px;
}

.av-card-bottom-right-info span:first-child {
  margin-left: 0;
}

.av-card-bottom-left-info {
  text-align: left;
  width: 55%;
  display: table-cell;
  vertical-align: middle;
  padding: 0;
  height: 100%;
}

.av-card .lastpostavatar img {
  min-width: 34px;
  width: 34px;
  top: 3px;
  margin-right: 10px;
}

.av-card-no-posts {
  font-size: 13px;
}

.av-card-bottom-left-info .lastpostavatar {
  width: 53px;
}

.av-card-bottom-left-info a.lastsubject {
  white-space: nowrap;
  font-size: 13px;
}

.av-card-bottom-left-info a.lastsubject + div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.av-card-bottom-left-info .av-card-last-post-username {
  overflow: hidden;
  width: -webkit-calc(100% - 44px);
  width: -moz-calc(100% - 44px);
  width: calc(100% - 44px);
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  font-size: 12px;
  top: 1px;
  position: relative;
}

.av-card {
  width: 16.6666666667%;
  position: relative;
}

.av-cards ul.forums {
  margin: 0 -12.5px;
}

@media (min-width: 2101px) and (max-width: 2560px) {
  .av-card {
    width: 20%;
  }
  .av-vertical-menu .av-card {
    width: 20%;
  }
  .av-vertical-menu.av-v-menu-mini.has-sidebar.av-no-sidebar .av-card {
    width: 16.6666666667%;
  }
  .av-full-boxed-content.av-horizontal-menu .av-card {
    width: 33.3333333333%;
  }
}

@media (min-width: 1801px) and (max-width: 2100px) {
  .av-no-sidebar .av-card, .av-v-menu-mini .av-card {
    width: 25%;
  }
  .av-card {
    width: 33.3333333333%;
  }
}

@media (min-width: 1571px) and (max-width: 1800px) {
  .av-no-sidebar .av-card {
    width: 33.3333333333%;
  }
  .av-no-sidebar.av-v-menu-mini .av-card {
    width: 25%;
  }
  .av-card {
    width: 33.3333333333%;
  }
}

@media (min-width: 1481px) and (max-width: 1570px) {
  .av-v-menu-mini .av-card {
    width: 33.3333333333%;
  }
  .av-no-sidebar .av-card {
    width: 33.3333333333%;
  }
  .av-horizontal-menu .av-card {
    width: 33.3333333333%;
  }
  .av-card {
    width: 50%;
  }
}

@media (min-width: 1201px) and (max-width: 1480px) {
  .av-no-sidebar .av-card {
    width: 33.3333333333%;
  }
  .av-horizontal-menu .av-card {
    width: 33.3333333333%;
  }
  .av-card {
    width: 50%;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .av-card {
    width: 33.3333333333%;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .av-card {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .av-card {
    width: 100%;
  }
}

.av-cards li.row {
  margin-bottom: 25px;
}

.av-cards ul.topiclist li.header {
  margin-bottom: 25px;
}

.av-cards ul.topiclist dt {
  margin-right: 0;
}

.av-cards ul.topiclist dt .list-inner {
  margin-right: 0;
}

.av-card-forum-icons .av-unapproved-ic {
  font-size: 18px;
}

/* MEMBERLIST CARDS
---------------------------------------- */
.av-cards-members ul.av-cards-members-list {
  margin-right: -25px;
}

.av-cards-members li.av-card {
  height: 289px;
  width: 252px;
  list-style: none;
  margin-right: 25px;
  margin-bottom: 25px;
  float: left;
  padding: 0;
  position: relative;
  overflow-y: auto;
}

.av-cards-members .av-hidden-panel, .av-cards-members .av-card-expanded .av-shown-panel {
  display: none;
}

.av-cards-members .av-card-expanded .av-hidden-panel {
  display: block;
}

.av-cards-members .av-memb-avatar {
  height: 156px;
  position: relative;
  text-align: center;
}

.av-cards-members .av-memb-avatar img {
  display: inline-block !important;
  vertical-align: middle;
  max-width: 90px;
  margin-top: 40px;
}

.av-cards-members .av-memb-info {
  padding: 0 25px;
  text-align: center;
}

.av-cards-members .av-memb-info .av-username {
  padding-bottom: 15px;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.av-cards-members .av-memb-info .av-username a {
  font-size: 14px;
}

.av-cards-members .av-memb-info .av-card-row {
  padding-bottom: 15px;
}

.av-cards-members .av-card-row {
  font-size: 13px;
}

.av-cards-members .av-memb-info .av-card-row .av-left-info {
  margin-right: 10px;
  font-weight: 500;
}

.av-cards-members .av-custom {
  padding-top: 55px;
  padding-bottom: 15px;
  overflow: hidden;
}

.av-cards-members .av-custom div {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.av-cards-members .av-custom .av-noinfo {
  margin-top: 80px;
}

.av_fci_current img {
  max-width: 250px;
  max-height: 250px;
}
